import { DataTable, TableToolbar, useTable } from '@pay/admin-data-table';
import { Page, PageTitle, SimplePopup, useModals, useNotificationsStore } from '@pay/admin-ui-kit';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'startup/utils';
import { BlackboxData } from 'apis-generated/mapper-processing-admin';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useSchoolService, useSchoolEvents, useSchoolStore } from '../module';
import { CreateSchoolModal } from './CreateSchool';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { SchoolViewDTO } from 'apis-generated/school-meal';
import { useSchoolColumns } from './tableDefs';
import { UploadSchoolsModal } from './UploadSchoolsModal';
import { downloadFile } from 'modules/common/utils';

interface IProps {}

export const SchoolsPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const { fetchTable, deleteSchool, getTemplate } = useSchoolService();
  const { onRefreshTable$ } = useSchoolEvents();
  const { showNotification } = useNotificationsStore();
  const store = useSchoolStore();

  const { showModal } = useModals();

  const handleDeleteClick = useCallback(
    (school: SchoolViewDTO) => {
      const title = (school.number && `№${school.number}`) ?? school.name ?? '';
      showModal((close) => (
        <SimplePopup
          title={t('meal_school_delete')}
          onOk={() => {
            return pipe(
              deleteSchool(school.bin),
              TE.match(
                () => {
                  showNotification({
                    text: t('meal_school_delete_failured', { title }),
                    options: { variant: 'error' },
                  });
                  close();
                },
                () => {
                  showNotification({
                    text: t('meal_school_delete_successed', { title }),
                    options: { variant: 'success' },
                  });
                  refresh();
                  close();
                }
              )
            )();
          }}
          okLabel={t('common_delete')}
          content={t('meal_school_delete_ask', { title })}
        />
      ));
    },
    [deleteSchool, showModal, showNotification, t]
  );

  const handleEditClick = useCallback(
    (school: SchoolViewDTO) => {
      showModal(() => <CreateSchoolModal onSuccess={refresh} open={true} school={school} />);
    },
    [showModal]
  );

  const columns = useSchoolColumns(t, handleEditClick, handleDeleteClick);

  const { tableProps, toolBarProps, refresh } = useTable({
    fetchData: fetchTable,
    initialQuery: {
      page: 0,
      pageSize: 20,
      sortings: {},
      filters: {},
    },
    columns,
    keyGetter: (it) => it.bin,
  });

  const handleAddClick = () => {
    showModal(() => <CreateSchoolModal onSuccess={refresh} open={true} />);
  };

  const [uploadMenuAnchorEl, setUploadMenuAnchorEl] = useState<HTMLButtonElement | null>();

  const handleUploadClick = () => {
    handleUploadMenuClose();
    showModal(<UploadSchoolsModal onUploaded={refresh} open={false} />);
  };

  const handleDownloadTemplateClick = useCallback(() => {
    handleUploadMenuClose();
    return pipe(
      getTemplate(),
      TE.map((file) => {
        const fileName = `Шаблон список школ.xls`;
        return downloadFile(file, fileName);
      }),
      TE.mapLeft((err) => {
        showNotification({
          text: t('common_download_error'),
          options: { variant: 'error' },
        });
      })
    )();
  }, [getTemplate, showNotification, t]);

  const handleUploadMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setUploadMenuAnchorEl(event.currentTarget);
  };

  const handleUploadMenuClose = () => {
    setUploadMenuAnchorEl(null);
  };

  return (
    <Page>
      <PageTitle>{t('meal_schools')}</PageTitle>
      <TableToolbar<BlackboxData> {...toolBarProps} columns={columns}>
        <Button variant="contained" color="primary" onClick={handleUploadMenuClick}>
          {t('meal_school_excel_actions')}
        </Button>
        <Menu
          anchorEl={uploadMenuAnchorEl}
          open={Boolean(uploadMenuAnchorEl)}
          onClose={() => handleUploadMenuClose()}
        >
          <MenuItem onClick={handleUploadClick}>{t('meal_school_upload')}</MenuItem>
          <MenuItem onClick={handleDownloadTemplateClick}>
            {t('meal_school_download_template')}
          </MenuItem>
        </Menu>
        <Button variant="contained" color="primary" onClick={handleAddClick}>
          {t('meal_school_add')}
        </Button>
      </TableToolbar>
      <DataTable {...tableProps} />
    </Page>
  );
};

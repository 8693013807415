import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@pay/mui-enhancement';
import { Box, BoxProps } from '@material-ui/core';
import { identity, pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';

import { IRemoteError } from 'modules/common/store';
import { IAuthLoginResponse } from '../service/AuthService';
import { SecondFactorCodeForm } from './second-factor/SecondFactorCodeForm';
import { I2FaVerificationError } from '../constants';
import { LoadingOverlay } from './LoadingOverlay';

interface IProps {
  onSubmit: (code: string) => TE.TaskEither<IRemoteError | I2FaVerificationError, undefined>;
  authResponse: IAuthLoginResponse;
  buttonBoxProps?: BoxProps;
}

export const AuthCodeForm: React.FC<IProps> = observer((props) => {
  const { onSubmit, authResponse, buttonBoxProps } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    (code: string) => {
      setIsLoading(true);
      return pipe(
        onSubmit(code),
        T.map(E.fold(identity, identity)),
        T.chainFirst((_) => {
          setIsLoading(false);
          return T.of(_);
        })
      )();
    },
    [onSubmit]
  );

  return (
    <>
      <SecondFactorCodeForm
        qrContext={
          authResponse.activated2FA
            ? undefined
            : // Quick fix, should be always non-nullable if activated2FA false
              { qrUrl: authResponse.qrUrl!, secret: authResponse.secret! }
        }
        formId="CodeForm"
        onSubmit={handleSubmit}
      />
      <Box mt={3} mb={5} textAlign="right" {...buttonBoxProps}>
        <Button
          size="large"
          variant="contained"
          fullWidth
          type="submit"
          color="primary"
          disableElevation
          loading={isLoading}
          form="CodeForm"
          data-test-id="submit"
        >
          {t('common_submit')}
        </Button>
      </Box>
      <LoadingOverlay in={isLoading} />
    </>
  );
});

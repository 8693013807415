import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import {
  BOReportRequest,
  DownloadPathParams,
  MealReportsControllerApiInterface,
  StatisticReportQueryParams,
} from 'apis-generated/reports';
import { TEtoPromise } from 'modules/common/fpts-utils';

export const makeReportService = (api: MealReportsControllerApiInterface) => {
  return {
    downloadStatsReport: (params: StatisticReportQueryParams) => {
      return pipe(() => api.statisticReport(params), T.map(mapFetcherResultEither));
    },
    downloadExcelReportBySchoolVauchers: (requestBody: BOReportRequest) => {
      return pipe(
        () =>
          api.excelReportBySchoolVouchers({
            bOReportRequest: requestBody,
          }),
        T.map(mapFetcherResultEither)
      );
    },
    list: () => {
      return pipe(
        () => api.list(),
        T.map(mapFetcherResultEither),
        TE.map((items: any) => {
          return { items, total: items.length };
        }),
        TEtoPromise
      );
    },
    download: (params: DownloadPathParams) => {
      return pipe(() => api.download(params), T.map(mapFetcherResultEither));
    },
  };
};

export type ReportService = ReturnType<typeof makeReportService>;

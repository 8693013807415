import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormHelperText,
  Button as MuiButton,
} from '@material-ui/core';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'startup/utils';
import { useTaskEither } from 'modules/common/async/hooks';
import { useForm } from 'react-hook-form';
import { ErrorAlert } from 'modules/common/ui/errors';
import { ITransKey } from 'startup/i18n';
import { CloudUpload } from '@material-ui/icons';
import { Button } from '@pay/mui-enhancement';
import { useNotificationsStore } from '@pay/admin-ui-kit';
import { IS_DEV } from 'startup/constants';
import { useSchoolService } from '../module';

interface IProps extends Omit<DialogProps, 'children'> {
  onClose?: () => void;
  onUploaded?: () => void;
}

type Values = {
  file: FileList;
};

export const UploadSchoolsModal: FC<IProps> = ({ onClose, onUploaded, ...dialogProps }) => {
  const { t } = useTranslation();

  const { uploadSchools } = useSchoolService();
  const { execute, state, reset } = useTaskEither(uploadSchools);

  const { showNotification } = useNotificationsStore();

  const {
    handleSubmit,
    formState,
    formState: { errors },
    register,
    watch,
  } = useForm<Values>({ mode: 'onChange' });
  const file = watch('file');

  const handleUploadFile = useCallback(
    (values: Values) => {
      if (values.file[0]) {
        const formData = new FormData();
        const file = values.file[0];
        formData.append('file', file);
        return execute(formData);
      }
    },
    [execute]
  );

  useEffect(() => {
    if (state?.value !== undefined) {
      onUploaded?.();
      showNotification({ options: { variant: 'success' }, text: t('meal_school_uploaded') });
      onClose?.();
    }
  }, [state, onUploaded, onClose, showNotification, t]);

  return (
    <>
      <Dialog
        {...dialogProps}
        onClose={onClose}
        PaperProps={{
          style: { width: 488 },
          component: 'form',
          onSubmit: handleSubmit(handleUploadFile),
        }}
      >
        <DialogTitle>{t('meal_school_select_file')}</DialogTitle>
        <DialogContent dividers>
          <ErrorAlert
            error={state?.error?.serverError?.errors?.map((e) => `• ${e.description}`).join('\n')}
          />
          <Box mb={1} display="flex" flexDirection="column" alignItems="center">
            <MuiButton
              variant="contained"
              component="label"
              color="primary"
              startIcon={<CloudUpload />}
            >
              {t('common_select_file')}

              <input
                {...register('file', {
                  validate: makeFileValidete(t),
                  onChange: reset,
                })}
                type="file"
                hidden
                accept=".xls,.xlsx"
                name="file"
              />
            </MuiButton>
            {errors.file && (
              <FormHelperText error={true}>{t(errors.file.message as ITransKey)}</FormHelperText>
            )}
            {file && <FormHelperText>{file[0]?.name}</FormHelperText>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('common_close')}
          </Button>
          <Button
            loading={formState.isSubmitting}
            disabled={!formState.isValid}
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
          >
            {t('common_upload')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const makeFileValidete = (t: (key: ITransKey) => string) => (value?: FileList) => {
  if (!value?.[0]) return t('meal_school_xls_required');

  const file = value[0];
  const maxFileSize = !IS_DEV ? 20 * 1024 * 1024 : 50 * 1024 * 1024;
  if (file.size > maxFileSize) {
    return t('meal_school_xls_upload_size_error');
  }
};

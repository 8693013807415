import { SortDirection } from '@pay/admin-data-table';
import { Expression, transformToRSQL } from '@pay/admin-resources';
import { IFetcher } from '@pay/data-fetching';
import {
  BackofficeNonmontaryControllerApiInterface,
  Pageable,
} from 'apis-generated/mapper-admin-nonmonetary-admin';
import {
  BlackboxApiInterface,
  DeleteWorkspacePathParams,
  FinancialOrganizationApiInterface,
  GetWorkspaceStatsQueryParams,
  WorkspaceApiInterface,
} from 'apis-generated/mapper-processing-admin';
import {
  AgreementApiInterface,
  BackofficeUserApiInterface,
  RolePermissionApiInterface,
  SmsApiInterface,
  VerifyApiInterface,
  PrivacyPolicyApiInterface,
  DownloadPrivacyPolicyPathParams,
  PrivacyPolicyHistoryQueryParams,
  PrivacyPolicyUpdateScheduleHistoryQueryParams,
  UpdatePrivacyPolicyOperationRequestBody,
  BackofficeMealUserApiInterface,
} from 'apis-generated/mapper-sso-admin';
import {
  BOReportRequest,
  DictionaryControllerApiInterface,
  ExcelReportBySchoolVouchersRequestBody,
  GetLocationsQueryParams,
  GetNextLocationQueryParams,
  GetSchoolsQueryParams,
  MealReportsControllerApiInterface,
  StatisticReportQueryParams,
} from 'apis-generated/reports';

import { DATE_FORMAT } from 'modules/common/constants';
import { toApiDate } from 'modules/common/date';
import { formatDate } from 'modules/common/format';
import { isDefined } from 'modules/common/typescript';
import uuid from 'uuid';

export type PagingReq = {
  size: number;
  page: number;
};

const convertPaging = (pagingState: PagingReq) => {
  return `page=${pagingState.page}&size=${pagingState.size}`;
};

export type SortingReq = {
  field: string;
  direction: SortDirection;
};

const convertSorting = (sortingState: SortingReq) => {
  return `sort=${sortingState.field},${sortingState.direction}`;
};

// TODO: This should be inside @pay/admin-resources
export const fetchWithRsql = <T extends object, Key extends string = 'items'>(
  fetcher: IFetcher,
  resourceName: string,
  initReq?: RequestInit
) => (paging?: PagingReq, query?: Expression, sorting?: SortingReq) => {
  const pagingParams = paging ? convertPaging(paging) : undefined;
  const queryParams = query ? `search=${transformToRSQL(query)}` : undefined;
  const sortingParams = sorting ? convertSorting(sorting) : undefined;
  const params = [pagingParams, queryParams, sortingParams].filter(isDefined).join('&');
  return fetcher.get<{ [K in Key]: T[] } & { total: number }, unknown>(
    resourceName + (params ? `?${params}` : ''),
    initReq
  );
};

export const makeVerifyApi = (ssoFetcher: IFetcher): VerifyApiInterface => {
  return {
    verify2FABackoffice: (args) =>
      ssoFetcher.postJson('bo/verify', args.verifyRequest, { credentials: 'include' }),
  };
};

export const makeWorkspaceApi = (fetcher: IFetcher): WorkspaceApiInterface => {
  return {
    deleteWorkspace: (pathParams: DeleteWorkspacePathParams) =>
      fetcher.delete(`bo/workspace/${pathParams.id}`),

    getWorkspaceStats: (queryParams: GetWorkspaceStatsQueryParams) =>
      fetcher.get(
        `bo/workspace/stats?from=${formatDate(
          queryParams.from,
          DATE_FORMAT.API_DATE_ONLY
        )}&to=${formatDate(queryParams.to, DATE_FORMAT.API_DATE_ONLY)}`
      ),
    addEntrepreneurWorkspace: ({ legalId }, { username }) =>
      fetcher.postJson(`bo/workspace/add/${username}/entrepreneur`, legalId),
    addLegalWorkspace: ({ legalId }, { username }) =>
      fetcher.postJson(`bo/workspace/add/${username}/legal`, legalId),
    blockWorkspace: ({ id }) => fetcher.post(`bo/workspace/block/${id}`),
    unblockWorkspace: ({ id }) => fetcher.post(`bo/workspace/unblock/${id}`),
  };
};

export const makeSmsApi = (fetcher: IFetcher): SmsApiInterface => {
  return {
    getSmsGatewayStatus: () => fetcher.get('bo/sms/status'),
    updateSmsGateway: (args) => fetcher.putJson('bo/sms/update', args.updateSmsEnabledValueRequest),
  };
};

export const makeOrganizationsApi = (fetcher: IFetcher): FinancialOrganizationApiInterface => {
  return {
    addFinancialOrganization: (arg) => {
      throw new Error('Not implemented');
    },
    checkAvailability: (arg) => {
      throw new Error('Not implemented');
    },
    deleteFinancialOrganization: (arg) => {
      throw new Error('Not implemented');
    },
    editFinancialOrganization: (arg) => {
      throw new Error('Not implemented');
    },
    getFinancialOrganization: (arg) => fetcher.get(`bo/organization/${arg.bin}`),
    getFinancialOrganizations: (arg) => {
      throw new Error('Not implemented');
    },
    financialOrganizationDashboardData: (arg) => fetcher.get('bo/organization/dashboard'),
    financialOrganizationGenerateQrPdf: (arg) => fetcher.postJson('bo/qr/pdf', arg.bOPdfQRRequest),
  };
};

export const makeDashboardApi = (fetcher: IFetcher): FinancialOrganizationApiInterface => {
  return {
    addFinancialOrganization: () => {
      throw new Error('Not implemented');
    },
    checkAvailability: () => {
      throw new Error('Not implemented');
    },
    deleteFinancialOrganization: () => {
      throw new Error('Not implemented');
    },
    editFinancialOrganization: () => {
      throw new Error('Not implemented');
    },
    financialOrganizationDashboardData: (arg) =>
      fetcher.postJson('bo/organization/dashboard', arg.dashboardDataRequest),
    getFinancialOrganization: () => {
      throw new Error('Not implemented');
    },
    getFinancialOrganizations: (args) => fetcher.get('bo/organizations'),
    financialOrganizationGenerateQrPdf: (arg) => fetcher.postJson('bo/qr/pdf', arg.bOPdfQRRequest),
  };
};

export const makeBlackboxApi = (fetcher: IFetcher): BlackboxApiInterface => {
  return {
    allBlackboxes: (args) => fetcher.get('/bo/blackboxes'),
    approveBlackbox: (args) => fetcher.postJson('bo/blackbox/approve', args.blackboxApproveRequest),
    getBlackbox: (args) => fetcher.get(`bo/blackbox/${args.bin}`),
  };
};

export const makeBackofficeUserApi = (ssoFetcher: IFetcher) => {
  const api: BackofficeUserApiInterface = {
    acceptUser2FA: (args) => ssoFetcher.putJson('bo/user/2fa', args.backofficeAccept2FAUserReqDto),
    addUser: (args) => ssoFetcher.postJson('bo/users', args.backofficeUserAddRequest),
    changeCurrentUserKey: (args) =>
      ssoFetcher.putJson('bo/user/key', args.backofficeChangeCurrentKeyReqDto),
    changeUserKey: (args) => {
      throw new Error('Method not supported');
    },
    editUser: (body, path) =>
      ssoFetcher.putJson(`bo/user/${path.id}`, body.backofficeUserEditReqDto),
    generateUser2FA: (args) =>
      ssoFetcher.postJson('bo/user/2fa', args.backofficeGenerate2FAUserReqDto),
    getCurrentUser: () => ssoFetcher.get('bo/user', { credentials: 'include' }),
    getUser: (args) => ssoFetcher.get(`bo/user/${args.id}`),
    getUsersList: (args) => {
      throw new Error('Method not supported');
    },
    logout: () => ssoFetcher.post('bo/user/logout'),
    removeUser: (body, path) => {
      return ssoFetcher.deleteJson(`bo/user/${path.id}`, body.backofficeRemoveUserReqDto);
    },
    resetUser2FA: (body, path) =>
      ssoFetcher.deleteJson(`bo/user/${path.id}/2fa`, body.backofficeReset2FAUserReqDto),
    resetUserKey: (body, path) =>
      ssoFetcher.deleteJson(`bo/user/${path.id}/key`, body.backofficeResetUserReqDto),
    getUsersLog: (args) => {
      throw new Error('Method not supported');
    },
  };
  return api;
};

export const makeRolesApi = (fetcher: IFetcher) => {
  const api: RolePermissionApiInterface = {
    addRole: (args) => fetcher.postJson('bo/role', args.roleAddReqDto),
    editRole: (body, pathParams) =>
      fetcher.putJson(`bo/role/${pathParams.id}`, body.roleEditReqDto),
    getPermissions: () => fetcher.get('bo/permissions'),
    getRole: (args) => fetcher.get(`bo/role/${args.id}`),
    getRoles: () => fetcher.get('bo/roles'),
    removeRole: (args) => fetcher.delete(`bo/role/${args.id}`),
  };
  return api;
};

export const makeAgreementApi = (ssoFetcher: IFetcher): AgreementApiInterface => {
  return {
    updateAgreement: (args) => ssoFetcher.postJson('bo/agreement', args.updateAgreementRequest),
    agreementHistory: (args) => {
      throw new Error('Method not supported');
    },
    agreementUpdateScheduleHistory: (args) => {
      throw new Error('Method not supported');
    },
    downloadAgreement: (args) => {
      throw new Error('Method not supported');
    },
    agreementLegalHistory: (args) => {
      throw new Error('Method not supported');
    },
    downloadLegalAgreement: (args) => {
      throw new Error('Method not supported');
    },
    legalAgreementUpdateScheduleHistory: (args) => {
      throw new Error('Method not supported');
    },
    updateLegalAgreement: (args) =>
      ssoFetcher.postJson('bo/legalagreement', args.updateAgreementRequest),
  };
};

export const makePrivacyPolicyApi = (ssoFetcher: IFetcher): PrivacyPolicyApiInterface => {
  return {
    downloadPrivacyPolicy: (pathParams: DownloadPrivacyPolicyPathParams) =>
      ssoFetcher.get(`bo/privacy-policy/download/${pathParams.type}/${pathParams.id}`),
    privacyPolicyHistory: (queryParams: PrivacyPolicyHistoryQueryParams) =>
      ssoFetcher.get('bo/privacy-policy/history'),
    updatePrivacyPolicy: (requestParameters: UpdatePrivacyPolicyOperationRequestBody) =>
      ssoFetcher.postJson('bo/privacy-policy', requestParameters.updatePrivacyPolicyRequest),
    privacyPolicyUpdateScheduleHistory: (
      queryParams: PrivacyPolicyUpdateScheduleHistoryQueryParams
    ) => ssoFetcher.get('bo/privacy-policy/update-schedule'),
  };
};

export const makeNonmontaryApi = (
  fetcher: IFetcher
): BackofficeNonmontaryControllerApiInterface => {
  return {
    addSchool1: ({ mNGLegalSchoolRequest }, { bin }) =>
      fetcher.postJson(
        `bo/nonmonetary/organization/manage/${bin}/legal/school/add`,
        mNGLegalSchoolRequest,
        {
          headers: { 'X-Request-ID': uuid() },
        }
      ),
    deleteSchool1: ({ bin, externalId, schoolBin }) =>
      fetcher.delete(
        `bo/nonmonetary/organization/manage/${bin}/legal/school/${externalId}/${schoolBin}`,
        {
          headers: { 'X-Request-ID': uuid() },
        }
      ),
    getSchools2: ({ bin }, { search }) =>
      fetcher.get(`bo/nonmonetary/organization/manage/${bin}/legal/school/add?search=${search}`, {
        headers: { 'X-Request-ID': uuid() },
      }),
    getSchools3: ({ bin, externalId }) =>
      fetcher.get(`bo/nonmonetary/organization/manage/${bin}/legal/schools/${externalId}`, {
        headers: { 'X-Request-ID': uuid() },
      }),
    editRole1: (args) => {
      throw new Error('Method not supported');
    },
    getLegalInfo2: (args) => {
      throw new Error('Method not supported');
    },
    getLegals1: (args) => {
      throw new Error('Method not supported');
    },
    getWorkers2: (args) => {
      throw new Error('Method not supported');
    },
  };
};

export const makeReportApi = (fetcher: IFetcher): MealReportsControllerApiInterface => {
  return {
    statisticReport: (queryParamsArg: StatisticReportQueryParams) => {
      const queryParams: StatisticReportQueryParams = {
        dateFrom: toApiDate(queryParamsArg.dateFrom),
        dateTo: toApiDate(queryParamsArg.dateTo),
      };
      return fetcher.get(`bo/meal/stats`, { queryParams });
    },
    download: () => {
      throw new Error('Not implemented');
    },
    excelReportBySchoolVouchers: (requestBody: ExcelReportBySchoolVouchersRequestBody) => {
      const { dateFrom, dateTo, ...args } = requestBody.bOReportRequest;
      const requestBodyValues: BOReportRequest = {
        dateFrom: toApiDate(dateFrom as Date),
        dateTo: toApiDate(dateTo as Date),
        ...args,
      };

      return fetcher.postJson(`bo/meal/excel/meal-voucher-report`, requestBodyValues);
    },
    list: () => {
      throw new Error('Not implemented');
    },
    reportBySchoolVauchers: () => {
      throw new Error('Not implemented');
    },
    reportBySchoolVauchers1: () => {
      throw new Error('Not implemented');
    },
    view: () => {
      throw new Error('Not implemented');
    },
  };
};

export const makeBOMealUserApi = (fetcher: IFetcher): BackofficeMealUserApiInterface => {
  return {
    addBackofficeMealUser: (args) => fetcher.postJson('bo/user/meal', args.bOMealUserAddRequest),
    boUserMealListGet: () => fetcher.get('bo/user/meal/list'),
    boUserMealRolesGet: () => fetcher.get('bo/user/meal/roles'),
    deleteBackofficeMealUser: (body, path) =>
      fetcher.deleteJson(`bo/user/meal/${path.id}`, body.backofficeRemoveUserReqDto),
    editBackofficeMealUser: (body, path) =>
      fetcher.putJson(`bo/user/meal/${path.id}`, body.bOMealUserEditRequest),
    getCurrentBackofficeMealUser: () => {
      throw new Error('Not implemented');
    },
    viewBackofficeMealUser: (args) => fetcher.get(`bo/user/meal/${args.id}`),
  };
};

export const makeCompanyServiceApi = (fetcher: IFetcher): DictionaryControllerApiInterface => {
  return {
    getServiceCompanies: () => fetcher.get(`bo/dictionary/service_companies`),
    getSchools: (queryParams: GetSchoolsQueryParams) =>
      fetcher.get(`bo/dictionary/schools`, { queryParams }),
    getLocations: (queryParams: GetLocationsQueryParams) =>
      fetcher.get(`bo/dictionary/locations`, { queryParams }),
    getNextLocation: (queryParams: GetNextLocationQueryParams) =>
      fetcher.get(`bo/dictionary/location/children`, { queryParams }),
  };
};

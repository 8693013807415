import { Box, Grow, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { ICommonError, IRemoteError } from 'modules/common/store';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { I2FaVerificationError } from '../../constants';
import { SECOND_AUTH_CODE_ERROR_TRANS_MAP } from '../constants';
import { secondAuthCodeValidator } from '../utils';
import { SecondAuthCodeField } from './SecondAuthCodeField';
import { AuthQrCodeBox } from './SecondFactorQrCodeBox';

interface FormValues {
  code: string;
}

const useStyles = makeStyles((theme) => ({
  codeFormWrap: {
    padding: `${theme.spacing()}px 0`, // to contain shadows and labels
    flex: '0 0 auto',
    position: 'relative',
    maxWidth: 300,
  },
}));

interface IProps {
  onSubmit: (
    code: string
  ) => Promise<ICommonError | IRemoteError | I2FaVerificationError | undefined>;
  qrContext:
    | {
        qrUrl: string;
        secret: string;
      }
    | undefined;
  formId: string;
}

export const SecondFactorCodeForm: React.FC<IProps> = observer((props) => {
  const classes = useStyles();
  const { onSubmit, formId, qrContext } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormValues>();
  const [error, setError] = useState<
    ICommonError | I2FaVerificationError | IRemoteError | undefined
  >(undefined);

  const handleSubmitInternal = useCallback(
    async (values: FormValues) => {
      setIsLoading(true);
      const error = await onSubmit(values.code);
      setError(error);
      setIsLoading(false);
    },
    [onSubmit]
  );

  const handlePasted = async (code: string) => {
    setValue('code', code);
  };

  return (
    <>
      <form
        id={formId}
        className={classes.codeFormWrap}
        data-test-id="codeForm"
        onSubmit={handleSubmit(handleSubmitInternal)}
      >
        <Box mb={2}>
          {qrContext ? (
            <AuthQrCodeBox qrUrl={qrContext.qrUrl} secret={qrContext.secret} />
          ) : (
            <Alert severity="info">{t('auth_code_description')}</Alert>
          )}
        </Box>
        <Grow in={!!error} unmountOnExit>
          <Box mb={2}>
            <Alert severity="error">
              <AlertTitle>{t('common_error')}</AlertTitle>
              {error && t(SECOND_AUTH_CODE_ERROR_TRANS_MAP[error.type])}
            </Alert>
          </Box>
        </Grow>
        <Box margin="auto">
          <Controller
            control={control}
            name={'code'}
            rules={{ validate: secondAuthCodeValidator }}
            render={({ field }) => (
              <SecondAuthCodeField
                {...field}
                disabled={isLoading}
                error={errors.code}
                onPasted={handlePasted}
              />
            )}
          ></Controller>
        </Box>
      </form>
    </>
  );
});
